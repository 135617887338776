import { BrowserRouter,Routes, Route} from "react-router-dom"
import Nav from "./Components/Nav"
import Submitform from "./Components/Submitform"
import Mobile from "./Pages/Mobileapp"
import Webdevelopment from "./Components/WebDevelopment"
import Uiux from "./Components/Uiux"
import Aidevelopment from "./Components/Aidevelopment"
import Cripto from "./Components/Cripto"
import Neft from "./Components/Neft"
import { ToastContainer } from "react-toastify"
import Homepage from "./Pages/Home"
import Discover from "./Pages/Discover"
import Aboutpage from "./Pages/Aboutpage"
import Servicepage from "./Pages/Servicepage"
import Careerpage from "./Pages/Careerpage"
import Contactpages from "./Pages/Contactpage"
import Privacypolicy from "./Components/Privacypolicy"
import Termsandconditions from "./Components/Termsandcondition"


let App=()=>{
 
    return (

        <div>
             <Nav></Nav>
              <ToastContainer />
                    <BrowserRouter>                  
                    <Routes>          
                    <Route element={<Homepage/>} path="/"></Route>
                    <Route element={<Homepage/>} path="homepage"></Route>
                    <Route element={<Aboutpage/>} path="/about"></Route>
                    <Route element={<Servicepage/>} path="/service"> </Route>
                    <Route element={<Careerpage/>} path="/career"></Route>
                    <Route element={<Contactpages/>} path="/contact"></Route>
                    <Route element={<Submitform/>} path="/apply"></Route>
                    <Route element={<Mobile/>} path="/service/mobileapp"></Route>
                    <Route element={<Webdevelopment/>} path="/service/webdesign"></Route>
                    <Route element={<Uiux/>} path="/service/uiux"></Route>
                    <Route element={<Aidevelopment/>} path="/service/aidevelopment"></Route>
                    <Route element={<Cripto/>} path="/service/crypto"></Route>
                    <Route element={<Neft/>} path="/service/nft"></Route>
                    <Route element={<Discover/>} path="/discover"></Route>
                    <Route element={<Privacypolicy/>} path="/privacypolicy"></Route>
                    <Route element={<Termsandconditions/>} path="/termsandconditions"></Route>
                    </Routes>
                    </BrowserRouter>
        </div>
    );
}
export default App