import React, { useEffect } from 'react';
import "../Assets/Css/home.css";
import video1 from "../Assets/Images/video1.mp4"
import video2 from "../Assets/Images/video2.mp4"
import video3 from "../Assets/Images/video3.mp4"
import video4 from "../Assets/Images/video4.mp4"

const Home = () => {

    useEffect(() => {

        const handleSlide = (event) => {
            const videos = document.querySelectorAll('.carousel-item video');
            videos.forEach((video) => {
                if (video.closest('.carousel-item').classList.contains('active')) {
                    video.currentTime = 0; // Restart video
                    video.play();
                } else {
                    video.pause();
                    video.currentTime = 0;
                }
            });
        };

        const carousel = document.querySelector('#carouselExampleAutoplaying');
        carousel.addEventListener('slid.bs.carousel', handleSlide);

        // Play the first video on page load
        const firstVideo = document.querySelector('.carousel-item.active video');
        if (firstVideo) {
            firstVideo.play();
        }

        // Clean up the event listener on component unmount
        return () => {
            carousel.removeEventListener('slid.bs.carousel', handleSlide);
        };
    }, []);

    return (
        <div className="">
            <div id="carouselExampleAutoplaying" className="carousel slide homeBackground container-fluid" data-bs-ride="carousel" data-bs-interval="3000">
                <div className="carousel-inner mt-5">
                    <div className="carousel-item active">
                        <div className="row">
                            <div className="col-md-6 d-flex flex-column justify-content-center">
                                <h1 className='contents-h1'>Crypto Trading</h1>
                                <p className='homeQuotes'>“Whenever the price of cryptocurrency is rallying, people start spending a lot more. — Erik Voorhees”</p>
                            </div>
                            <div className="col-md-6">
                                {/* <video id="video1" src="https://res.cloudinary.com/deowghzbg/video/upload/v1719643804/video1_qe2lm5.mp4" className="video-fluid video-shadow home-video" loop muted playsInline style={{height: "550px"}}></video> */}
                                <video src={video1} className="video-fluid video-shadow home-video" loop muted playsInline style={{height: "550px"}}></video>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item secondBackground">
                        <div className="row">
                            <div className="col-md-6 d-flex flex-column justify-content-center">
                                <h1 className='contents-h1'>Web Development</h1>
                                <p className='homeQuotes'> “Websites promote you 24/7: No employee will do that.” <br /> – Paul Cookson</p>
                            </div>
                            <div className="col-md-6">
                                {/* <video id="video2" src="https://res.cloudinary.com/deowghzbg/video/upload/v1719643904/video2_d4uxnx.mp4" className="video-fluid video-shadow home-video" loop muted playsInline style={{height: "550px"}}></video> */}
                                <video src={video2} className="video-fluid video-shadow home-video" loop muted playsInline style={{height: "550px"}}></video>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-md-6 d-flex flex-column justify-content-center">
                                <h1 className='contents-h1'>UI/UX Design</h1>
                                <p className='homeQuotes'>“Design is not just what it looks like and feels like. Design is how it works.” <br /> –Steve Jobs</p>
                            </div>
                            <div className="col-md-6">
                                {/* <video id="video3" src="https://res.cloudinary.com/deowghzbg/video/upload/v1721105691/Recording_2024-07-16_102037_online-video-cutter.com_c6rjhd.mp4" className="video-fluid video-shadow home-video" loop muted playsInline style={{height: "550px"}}></video> */}
                                <video src={video3} className="video-fluid video-shadow home-video" loop muted playsInline style={{height: "550px"}}></video>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-md-6 d-flex flex-column justify-content-center">
                                <h1 className='contents-h1'>AI Development</h1>
                                <p className='homeQuotes'> “Machine intelligence is the last invention that humanity will ever need to make.” <br /> – Nick Bostrom</p>
                            </div>
                            <div className="col-md-6">
                                {/* <video id="video4" src="https://res.cloudinary.com/deowghzbg/video/upload/v1721107163/Recording_2024-07-16_104558_online-video-cutter.com_tnlv94.mp4" className="video-fluid video-shadow home-video" loop muted playsInline style={{height: "550px"}}></video> */}
                                <video src={video4} className="video-fluid video-shadow home-video" loop muted playsInline style={{height: "550px"}}></video>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                <ol className="carousel-indicators">
                    <li data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1"></li>
                    <li data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2"></li>
                    <li data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="3"></li>
                </ol>
            </div>
        </div>
    );
}
export default Home;